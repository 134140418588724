$(document).ready(function() {
    /*light-gallery*/
    $("#lightgallery").lightGallery();

    $('#open-gallery').on('click', function(e){
        e.preventDefault();
        $('#lightgallery .place-gallery-item').first().trigger('click');
    });
});

/*gallery*/
$('.gallery .gallery-thumb').on('click', function(){
    var image = $(this).attr('data-image');
    $('.gallery .gallery-image').each(function() {
        $(this).removeClass('active');
    });
    $('.gallery .gallery-thumb').each(function() {
        $(this).removeClass('active');
    });
    $('.gallery .gallery-image').each(function() {
        if (image == $(this).attr('data-image')) {
            $(this).addClass('active');
        }
    });
    $(this).addClass('active');
});
/*map*/

function initMap() {
    if ($('#map').length) {
        var center = {lat: 50.125893, lng: 14.454820};
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 17,
            center: center,
            disableDefaultUI: true,
            styles: [
                {
                    elementType: 'geometry',
                    stylers: [{color: '#ebf4ff'}]
                },
                {
                    elementType: 'labels.icon',
                    stylers: [{visibility: 'off'}]
                },
                {
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#5d7f9b'}]
                },
                {
                    elementType: 'labels.text.stroke',
                    stylers: [{color: '#ffffff'}]
                },
                {
                    featureType: 'administrative.land_parcel',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#bdbdbd'}]
                },
                {
                    featureType: 'poi',
                    elementType: 'geometry',
                    stylers: [{color: '#ddecff'}]
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#5d7f9b'}]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{color: '#b5d4fc'}]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#5d7f9b'}]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{color: '#ffffff'}]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [{color: '#d1e3ff'}]
                },
                {
                    featureType: 'road.arterial',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#5d7f9b'}]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{color: '#ffffff'}]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#5d7f9b'}]
                },
                {
                    featureType: 'road.local',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#5d7f9b'}]
                },
                {
                    featureType: 'transit',
                    stylers: [{visibility: 'shown'}]
                },
                {
                    featureType: 'transit.line',
                    elementType: 'geometry',
                    stylers: [{color: '#90add1'}]
                },
                {
                    featureType: 'transit.station',
                    elementType: 'geometry',
                    stylers: [{color: '#eeeeee'}]
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{color: '#8abaf6'}]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{color: '#676767'}]
                }
            ]
        });
        var iconImage = {
            url: './images/marker.png'
        };
        var marker = new google.maps.Marker({
            position: center,
            icon: iconImage,
            map: map
        });
    }
}

/*menu scroll*/
var lastScrollTop = 0;
$(window).scroll(function(event){
    var st = $(this).scrollTop();
    var topHeight = $('.top-header').outerHeight();
    if (st > lastScrollTop){
        if (st > topHeight) {
            $('header').addClass('fixed-down');
            $('header').removeClass('fixed-up');
        }
    } else {
        if (st > topHeight) {
            $('header').addClass('fixed-up');
            $('header').removeClass('fixed-down');
        }
        if (st == 0) {
            $('header').removeClass('fixed-up');
        }
    }
    lastScrollTop = st;
});

/*mobile menu*/
$('.hamburger').on('click', function(){
    $('.mobile-menu, .menu-backdrop, body').addClass('opened');
});
$('.close-menu, .menu-backdrop').on('click', function(){
    $('.mobile-menu, .menu-backdrop, body').removeClass('opened');
});

